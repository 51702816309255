import type { PriceChangeQuery } from './definitions'
import type { PriceChange } from '~/types/domain/priceChange'

const allowedPriceTypes = ['Price', 'Global Weighted Average']

export const extractOverviewFactbox = (query: PriceChangeQuery, changes: PriceChange[]) => {
  return changes.find(({ category, grade, ...change }) => {
    return (
      allowedPriceTypes.includes(change.priceType.name) &&
      query.category === category.name &&
      query.name === change[query.type]?.name &&
      (!query.grade ? true : query.grade === grade)
    )
  })
}
