import { useHomeProducers } from './home/useHomeProducers'
import { useLithiumOverviewStore } from '~/stores/lithium/overview'
import { useLithiumSupplyChemicalCompanyStore } from '~/stores/lithium/supply/chemical/company'
import { useLithiumSupplyChemicalCountryStore } from '~/stores/lithium/supply/chemical/country'
import { useLithiumSupplyChemicalFeedstockStore } from '~/stores/lithium/supply/chemical/feedstock'
import { useLithiumSupplyChemicalOverviewStore } from '~/stores/lithium/supply/chemical/overview'
import { useLithiumSupplyChemicalPlantStore } from '~/stores/lithium/supply/chemical/plant'
import { useLithiumSupplyChemicalProductStore } from '~/stores/lithium/supply/chemical/product'
import { useLithiumSupplyChemicalRegionStore } from '~/stores/lithium/supply/chemical/region'
import { useLithiumSupplyMinedCompanyStore } from '~/stores/lithium/supply/mined/company'
import { useLithiumSupplyMinedCountryStore } from '~/stores/lithium/supply/mined/country'
import { useLithiumSupplyMinedMineStore } from '~/stores/lithium/supply/mined/mine'
import { useLithiumSupplyMinedOreTypeStore } from '~/stores/lithium/supply/mined/oreType'
import { useLithiumSupplyMinedOverviewStore } from '~/stores/lithium/supply/mined/overview'
import { useLithiumSupplyMinedRegionStore } from '~/stores/lithium/supply/mined/region'

export const useCacheControl = () => {
  const { clearState: clearStatelithiumOverview } = useLithiumOverviewStore()
  const { clearState: clearStateSupplyChemicalCompanyStore } =
    useLithiumSupplyChemicalCompanyStore()
  const { clearState: clearStateSupplyChemicalCountryStore } =
    useLithiumSupplyChemicalCountryStore()
  const { clearState: clearStateLithiumSupplyChemicalFeedstockStore } =
    useLithiumSupplyChemicalFeedstockStore()
  const { clearState: clearChemicalOvervewState } = useLithiumSupplyChemicalOverviewStore()
  const { clearState: clearStateLithiumSupplyChemicalPlantStore } =
    useLithiumSupplyChemicalPlantStore()
  const { clearState: clearStateLithiumSupplyChemicalProductStore } =
    useLithiumSupplyChemicalProductStore()
  const { clearState: clearStateLithiumSupplyChemicalRegionStore } =
    useLithiumSupplyChemicalRegionStore()
  const { clearState: clearStateLithiumSupplyMinedCompanyStore } =
    useLithiumSupplyMinedCompanyStore()
  const { clearState: clearStateLithiumSupplyMinedCountryStore } =
    useLithiumSupplyMinedCountryStore()
  const { clearState: clearStateLithiumSupplyMinedMineStore } = useLithiumSupplyMinedMineStore()
  const { clearState: clearStateLithiumSupplyMinedOreTypeStore } =
    useLithiumSupplyMinedOreTypeStore()
  const { clearState: clearStateLithiumSupplyMinedOverviewStore } =
    useLithiumSupplyMinedOverviewStore()
  const { clearState: clearStateLithiumSupplyMinedRegionStore } = useLithiumSupplyMinedRegionStore()
  const { clearWeightedUnweightedState } = useHomeProducers()

  const removeAllRequestCached = () => {
    clearStatelithiumOverview()
    clearStateSupplyChemicalCompanyStore()
    clearStateSupplyChemicalCountryStore()
    clearStateLithiumSupplyChemicalFeedstockStore()
    clearChemicalOvervewState()
    clearStateLithiumSupplyChemicalPlantStore()
    clearStateLithiumSupplyChemicalProductStore()
    clearStateLithiumSupplyChemicalRegionStore()
    clearStateLithiumSupplyMinedCompanyStore()
    clearStateLithiumSupplyMinedCountryStore()
    clearStateLithiumSupplyMinedMineStore()
    clearStateLithiumSupplyMinedOreTypeStore()
    clearStateLithiumSupplyMinedOverviewStore()
    clearStateLithiumSupplyMinedRegionStore()
    clearWeightedUnweightedState()
  }

  return {
    removeAllRequestCached,
  }
}
