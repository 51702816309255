import { defineStore, storeToRefs } from 'pinia'
import { computed, ref } from 'vue'
import { LITHIUM_OVERVIEW_FACTBOX_QUERIES } from './constant'
import { extractOverviewFactbox } from './utils'
import type { LithiumOverviewFactboxes } from './definitions'
import useLithium from '~/composables/http/useLithium'
import { DemandScenarios } from '~/constants/lithium/demand'
import type { PriceChange } from '~/types/domain/priceChange'
import formatFactboxData from '~/utils/formatFactboxData'
import { existProperty } from '~/utils/object.utils'
import { DECIMAL_PLACES, getPercentageChange } from '~/constants/general'
import { useGeneralStore } from '~/stores/general'

export const useLithiumOverviewStore = defineStore('lithium-overview', () => {
  const {
    getHeaders,
    getChemicalSupplyAndDemand,
    getChemicalSupplyAndDemandDetailed,
    getMinedSupplyAndDemand,
    getChemicalBalance,
    getMinedBalance,
    getChanges,
  } = useLithium()

  const generalStore = useGeneralStore()
  const { weightedUnweighted } = storeToRefs(generalStore)

  const SupplyAndDemand = ref({
    mined: {
      [DemandScenarios.baseDemand.key]: null,
      [DemandScenarios.highDemand.key]: null,
      [DemandScenarios.rawMaterialConstrained.key]: null,
    },
    chemical: {
      [DemandScenarios.baseDemand.key]: null,
      [DemandScenarios.highDemand.key]: null,
      [DemandScenarios.rawMaterialConstrained.key]: null,
    },
  })
  const balance = ref({
    mined: {
      [DemandScenarios.baseDemand.key]: null,
      [DemandScenarios.highDemand.key]: null,
      [DemandScenarios.rawMaterialConstrained.key]: null,
    },
    chemical: {
      [DemandScenarios.baseDemand.key]: null,
      [DemandScenarios.highDemand.key]: null,
      [DemandScenarios.rawMaterialConstrained.key]: null,
    },
  })
  const factboxDataV1 = ref()
  const factBoxDataV2 = ref<PriceChange[]>()

  const supplyAndDemandChemicalProduct = ref({
    [DemandScenarios.baseDemand.key]: null,
    [DemandScenarios.highDemand.key]: null,
    [DemandScenarios.rawMaterialConstrained.key]: null,
  })

  const supplyAndDemandMined = computed(() => SupplyAndDemand.value.mined)
  const supplyAndDemandChemical = computed(() => SupplyAndDemand.value.chemical)
  const balanceMined = computed(() => balance.value.mined)
  const balanceChemical = computed(() => balance.value.chemical)

  const clearState = () => {
    factboxDataV1.value = null
    factBoxDataV2.value = null
    SupplyAndDemand.value = {
      mined: {
        [DemandScenarios.baseDemand.key]: null,
        [DemandScenarios.highDemand.key]: null,
        [DemandScenarios.rawMaterialConstrained.key]: null,
      },
      chemical: {
        [DemandScenarios.baseDemand.key]: null,
        [DemandScenarios.highDemand.key]: null,
        [DemandScenarios.rawMaterialConstrained.key]: null,
      },
    }
    balance.value = {
      mined: {
        [DemandScenarios.baseDemand.key]: null,
        [DemandScenarios.highDemand.key]: null,
        [DemandScenarios.rawMaterialConstrained.key]: null,
      },
      chemical: {
        [DemandScenarios.baseDemand.key]: null,
        [DemandScenarios.highDemand.key]: null,
        [DemandScenarios.rawMaterialConstrained.key]: null,
      },
    }
    supplyAndDemandChemicalProduct.value = {
      [DemandScenarios.baseDemand.key]: null,
      [DemandScenarios.highDemand.key]: null,
      [DemandScenarios.rawMaterialConstrained.key]: null,
    }
  }

  const getScenarioId = (key: string) => DemandScenarios[key]?.id

  const fetchFactboxDataV1 = async () => {
    if (factboxDataV1.value) return factboxDataV1.value

    const { data: header } = await getHeaders(weightedUnweighted.value)

    const loadFactBoxData = {
      lithiumCarbonateGWA: header.carbonateGWA.value,
      priceRanges: header.priceRange,
      annualProductionChemical: header.annualProductionChemical.value,
      annualProductionMined: header.annualProductionMined.value,
      annualDemandMined: header.annualDemandMined.value,
    }

    const headersReplace = {
      lithiumCarbonateGWA: {
        compareStatusWithPastPrice: header.carbonateGWA.compareStatusWithPastPrice,
        pastPriceValue: header.carbonateGWA.previousValue,
      },
      annualProductionMined: {
        label: header.annualProductionMined.year,
        hasWeightProperty: existProperty(header.annualProductionMined, 'weighted'),
        isWeight: header.annualProductionMined.weighted,
      },
      annualProductionChemical: {
        label: header.annualProductionChemical.year,
        hasWeightProperty: existProperty(header.annualProductionChemical, 'weighted'),
        isWeight: header.annualProductionChemical.weighted,
      },
      annualDemandMined: {
        label: header.annualDemandMined.year,
      },
    }

    factboxDataV1.value = formatFactboxData(loadFactBoxData, null, null, headersReplace)
  }

  const factboxDataV2 = computed<LithiumOverviewFactboxes>(() => {
    if (!factBoxDataV2.value) return

    const lithiumCarbonateGlobal = extractOverviewFactbox(
      LITHIUM_OVERVIEW_FACTBOX_QUERIES['Lithium-Carbonate-Global-Weighted-Average'],
      factBoxDataV2.value,
    )

    const lithiumCarbonateChina = extractOverviewFactbox(
      LITHIUM_OVERVIEW_FACTBOX_QUERIES['Lithium-Carbonate-EXW-China'],
      factBoxDataV2.value,
    )

    const lithiumHydroxideChina = extractOverviewFactbox(
      LITHIUM_OVERVIEW_FACTBOX_QUERIES['Lithium-Hydroxide-EXW-China'],
      factBoxDataV2.value,
    )

    return {
      priceRange: {
        headers: [
          {
            label: 'Price Range $/T',
            columnDef: 'name',
          },
          {
            label: 'Low',
            columnDef: 'lowPrice',
          },
          {
            label: 'High',
            columnDef: 'highPrice',
          },
          {
            label: 'Mid',
            columnDef: 'midPrice',
          },
        ],
        value: [
          {
            name: 'EXW China Hydroxide',
            midPrice: {
              value: priceFormatter(
                lithiumHydroxideChina.currentValue.valueMid,
                undefined,
                DECIMAL_PLACES,
              ),
              variation: getPercentageChange(
                lithiumHydroxideChina.currentValue.valueMid,
                lithiumHydroxideChina.previousValue.valueMid,
              ),
            },
            lowPrice: {
              value: priceFormatter(
                lithiumHydroxideChina.currentValue.valueLow,
                undefined,
                DECIMAL_PLACES,
              ),
              variation: getPercentageChange(
                lithiumHydroxideChina.currentValue.valueLow,
                lithiumHydroxideChina.previousValue.valueLow,
              ),
            },
            highPrice: {
              value: priceFormatter(
                lithiumHydroxideChina.currentValue.valueHigh,
                undefined,
                DECIMAL_PLACES,
              ),
              variation: getPercentageChange(
                lithiumHydroxideChina.currentValue.valueHigh,
                lithiumHydroxideChina.previousValue.valueHigh,
              ),
            },
            date: lithiumHydroxideChina.currentValue.date,
            lastChange: lithiumHydroxideChina.previousValue.date,
          },
          {
            name: 'EXW China Carbonate',
            midPrice: {
              value: priceFormatter(
                lithiumCarbonateChina.currentValue.valueMid,
                undefined,
                DECIMAL_PLACES,
              ),
              variation: getPercentageChange(
                lithiumCarbonateChina.currentValue.valueMid,
                lithiumCarbonateChina.previousValue.valueMid,
              ),
            },
            lowPrice: {
              value: priceFormatter(
                lithiumCarbonateChina.currentValue.valueLow,
                undefined,
                DECIMAL_PLACES,
              ),
              variation: getPercentageChange(
                lithiumCarbonateChina.currentValue.valueLow,
                lithiumCarbonateChina.previousValue.valueLow,
              ),
            },
            highPrice: {
              value: priceFormatter(
                lithiumCarbonateChina.currentValue.valueHigh,
                undefined,
                DECIMAL_PLACES,
              ),
              variation: getPercentageChange(
                lithiumCarbonateChina.currentValue.valueHigh,
                lithiumCarbonateChina.previousValue.valueHigh,
              ),
            },
            variation: getPercentageChange(
              lithiumCarbonateChina.currentValue.valueMid,
              lithiumCarbonateChina.previousValue.valueMid,
            ),
            date: lithiumCarbonateChina.currentValue.date,
            lastChange: lithiumCarbonateChina.previousValue.date,
          },
        ],
      },
      hydroxideVsCarbonate: {
        label: ['EXW China Hydroxide vs', 'Carbonate Delta (premium)'],
        value:
          (lithiumCarbonateChina.currency?.symbol || '') +
          priceFormatter(
            lithiumCarbonateChina.currentValue.valueMid -
              lithiumHydroxideChina.currentValue.valueMid,
            undefined,
            DECIMAL_PLACES,
          ),
      },
      lithiumCarbonateGlobal: {
        label: ['Global Weighted Average', 'Carbonate $/T'],
        value: {
          variation: getPercentageChange(
            lithiumCarbonateGlobal?.currentValue.valueMid,
            lithiumCarbonateGlobal?.previousValue.valueMid,
          ),
          price:
            (lithiumCarbonateGlobal?.currency?.symbol || '') +
            priceFormatter(
              lithiumCarbonateGlobal?.currentValue.valueMid,
              undefined,
              DECIMAL_PLACES,
            ),
        },
      },
    }
  })

  const fetchFactboxDataV2 = async (): Promise<void> => {
    if (factBoxDataV2.value) return

    const { data: response } = await getChanges()

    factBoxDataV2.value = response.data.map(
      (price): PriceChange => ({
        category: price.category,
        shippingRoute: price.shippingRoute,
        grade: price.grade,
        currentValue: price.currentValue,
        previousValue: price.previousValue,
        priceType: price.priceType,
        currency: price.currency,
      }),
    )
  }

  const getSupplyAndDemandData = async (type, key: string) => {
    const scenarioId = getScenarioId(key)
    const oldData = SupplyAndDemand.value[type][key]

    if (oldData) return

    const requests = {
      chemical: getChemicalSupplyAndDemand,
      mined: getMinedSupplyAndDemand,
    }
    const response = await requests[type]({ scenarioId, valueType: weightedUnweighted.value })

    SupplyAndDemand.value = {
      ...SupplyAndDemand.value,
      [type]: {
        [key]: response,
      },
    }
  }

  const getBalanceData = async (type, key: string) => {
    const scenarioId = getScenarioId(key)
    const oldData = balance.value[type][key]
    if (oldData) return

    const requests = {
      chemical: getChemicalBalance,
      mined: getMinedBalance,
    }
    const response = await requests[type]({ scenarioId, valueType: weightedUnweighted.value })
    balance.value[type][key] = response
  }

  const getChemicalSupplyAndDemandProduct = async (key: string) => {
    const scenarioId = getScenarioId(key)
    const oldData = supplyAndDemandChemicalProduct.value[key]

    if (oldData) return
    const response = await getChemicalSupplyAndDemandDetailed({
      scenarioId,
      valueType: weightedUnweighted.value,
    })
    supplyAndDemandChemicalProduct.value[key] = response
  }

  const fetchFactboxData = async () => {
    const requests = []
    if (!factboxDataV2.value) {
      requests.push(fetchFactboxDataV2)
    }
    if (!factboxDataV1.value) {
      requests.push(fetchFactboxDataV1)
    }
    if (requests.length === 0) return

    await Promise.all(requests.map((request) => request()))
  }

  return {
    factboxDataV1,
    SupplyAndDemand,
    balance,
    supplyAndDemandMined,
    supplyAndDemandChemical,
    balanceMined,
    balanceChemical,
    supplyAndDemandChemicalProduct,
    fetchFactboxData,
    factboxDataV2,
    getSupplyAndDemandData,
    getBalanceData,
    getChemicalSupplyAndDemandProduct,
    clearState,
  }
})
